import * as React from 'react';
import tw, { styled } from 'twin.macro';
import { Trans } from '@lingui/macro';
import { BlockHeadline } from '../Headline';
import { appUrl } from '../../config';

import Container from '../Container';

const Wrapper = styled.div`
    ${tw`py-2 px-2 text-center`}
    /* width: 72px;
    height: 72px; */
    background-color: #0c1d37;
`;

const CTALink = styled.a`
    ${tw`inline-flex items-center w-full sm:w-auto flex-none bg-white hover:text-white text-white text-black leading-6 font-semibold py-3 px-6 border border-transparent rounded-xl focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-900 focus:outline-none transition-colors duration-200`}
    :hover {
        background-color: #ff6501;
    }
`;
const Svg = tw.svg`ml-3`;

const CallToAction = () => (
    <Wrapper>
        <Container>
            <BlockHeadline tw="text-white">
                <Trans>Ready to receive the feedback?</Trans>
            </BlockHeadline>
            <CTALink
                href={appUrl}
            >
                <Trans>Get Started for Free</Trans>
                <Svg width="7" height="12" viewBox="0 0 7 12" fill="none"><path d="M5.95 5.293l-4.24-4.24a1 1 0 10-1.42 1.41l3.54 3.54-3.54 3.54a1 1 0 000 1.41.998.998 0 00.71.29 1 1 0 00.71-.29l4.24-4.24a1 1 0 000-1.42z" fill="currentColor" /></Svg>
            </CTALink>
        </Container>
    </Wrapper>
);

export default CallToAction;
