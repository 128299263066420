import * as React from 'react';
import tw, { styled } from 'twin.macro';
import { t, Trans } from '@lingui/macro';
import { AiOutlineMail } from 'react-icons/ai';
import { BsPeople, BsShieldLock } from 'react-icons/bs';
import { BlockHeadline } from '../Headline';

import Container from '../Container';

const OutWrapper = tw.div`grid lg:grid-flow-col lg:gap-28 md:pb-16 md:pt-8`;
const FeatureCardWrapper = tw.div`text-center`;
const CardTitle = tw.p`text-xl mb-3 font-bold`;
const CardSubTitle = tw.p``;
const CardIcon = styled.div`
    ${tw`py-2 px-2 m-auto rounded mb-4 items-center flex justify-center`}
    width: 72px;
    height: 72px;
`;

const FeatureCard = ({ Icon, title, desc }) => (
    <FeatureCardWrapper>
        <CardIcon><Icon size="36px" /></CardIcon>
        <CardTitle>{title}</CardTitle>
        <CardSubTitle>{desc}</CardSubTitle>
    </FeatureCardWrapper>
);

const FeatureDetail = () => (
    <Container>
        <BlockHeadline>
            <Trans>Why moin is different?</Trans>
        </BlockHeadline>
        <OutWrapper>
            <FeatureCard
                Icon={AiOutlineMail}
                title={t`Built for busy person`}
                desc={t`Most of clients don't have the spare time to reply message in realtime. We are focus on providing you the best asyncronous communication experience`}
            />
            <FeatureCard
                Icon={BsPeople}
                title={t`Team collaboration`}
                desc={t`Provide 5 different roles. Seamlessly collaborated with different colleagues in your company`}
            />
            <FeatureCard
                Icon={BsShieldLock}
                title={t`Trusted and secure`}
                desc={t`You and user's privacy are our top criteria. We never trace users' data`}
            />
        </OutWrapper>
    </Container>
);

export default FeatureDetail;
