import * as React from 'react';
// Images
import './index.scss';
import Widget from './Widget';
import FeatureDetail from './FeatureDetail';
import CallToAction from './CallToAction';
import Reason from './Reason';
import HowItWorks from './HowItWorks';

const Index = () => (
    <>
        <Reason />
        <HowItWorks />
        <Widget />
        <FeatureDetail />
        <CallToAction />
        {/* <Testimonial /> */}
    </>
);
export default Index;
