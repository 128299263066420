import tw from 'twin.macro';

export const BlockHeadline = tw.h2`
    text-center text-3xl sm:text-5xl lg:text-5xl leading-none text-gray-900 tracking-tight mb-8
`;

export const IntroduceHeadline = tw.h3`
    text-2xl sm:text-4xl lg:text-4xl leading-none font-extrabold text-white tracking-tight mb-3
`;

export const IntroduceSubline = tw.p`
    text-lg sm:text-lg lg:text-lg text-gray-400 mb-5
`;
