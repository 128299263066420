import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import HomeHeader from '../components/HomeHeader';
import Home from '../components/Home';

const IndexRoute = ({ pageContext, data }) => {
    const { locale, seo } = pageContext;

    return (
        <Layout
            pageContext={pageContext}
            seo={seo}
            locale={locale}
        >
            <div css={{ backgroundColor: '#fdf8f4' }}>
                <HomeHeader />
                <Home />
            </div>
            <Footer />
        </Layout>
    );
};

export default IndexRoute;

export const pageQuery = graphql`
    query IndexQuery {
        site {
            siteMetadata {
                url
            }
        }
    }
`;
