import * as React from 'react';
import tw from 'twin.macro';
import { Trans } from '@lingui/macro';

import Container from '../Container';
import { BlockHeadline } from '../Headline';

const Li = tw.li`leading-relaxed`;

const Features = () => (
    <Container>
        <BlockHeadline>
            <Trans>
                Use moin to
            </Trans>
        </BlockHeadline>
        <div tw="w-full sm:w-7/12 m-auto">
            <ul tw="list-disc text-2xl">
                <Li>
                    🌐
                    {' '}
                    <Trans>
                        Add the message widget at your website
                    </Trans>
                </Li>
                <Li>
                    🖍
                    {' '}
                    <Trans>
                        Build customizable questions
                    </Trans>
                </Li>
                <Li>
                    👫
                    {' '}
                    <Trans>
                        Drive interaction and build valuable customer relationships
                    </Trans>
                </Li>

            </ul>
        </div>

    </Container>
);

export default Features;
