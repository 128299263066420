import * as React from 'react';
import tw, { styled } from 'twin.macro';
import { t, Trans } from '@lingui/macro';
import { down } from 'styled-breakpoints';
import { FiEdit2, FiSettings } from 'react-icons/fi';
import { RiShip2Line } from 'react-icons/ri';
import Container from '../Container';
import { BlockHeadline } from '../Headline';
import arrowSvg from './arrow.svg';

const Arrow = () => (
    <img
        src={arrowSvg}
        loading="lazy"
        css={{
            opacity: 1,
            transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            transformStyle: 'preserve-3d',
        }}
        alt=""
        tw="md:block hidden"
    />
);

const Step = ({
    icon, title, description, stepNum,
}) => (
    <div
        css={{
            opacity: 1,
            transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            transformStyle: 'preserve-3d',
        }}
        tw="text-center flex-col justify-start items-center flex"
    >
        <div
            tw="flex bg-yellow-100 relative rounded-lg items-center mb-5 p-5 flex-col justify-center"
        >
            <div
                tw="absolute bg-red-500 flex items-center justify-center"
                css={{
                    top: '-10px',
                    right: '-10px',
                    borderRadius: '50%',
                    width: '28px',
                    height: '28px',
                }}
            >
                <div tw="text-white" css={{ lineHeight: '100%' }}>{stepNum}</div>
            </div>
            {icon}
        </div>
        <h4 tw="text-2xl mb-2">{title}</h4>
        <p>{description}</p>
    </div>
);

const Wrapper = styled.div`
    ${tw`grid gap-10 items-center mt-20`}
    grid-template-columns: 1fr 0.4fr 1fr 0.4fr 1fr;
    ${down('md')} {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
`;

const Features = () => (
    <Container>
        <BlockHeadline>
            <Trans>
                How it works
            </Trans>
        </BlockHeadline>
        <Wrapper>
            <Step
                icon={<FiEdit2 tw="text-red-600 text-3xl" />}
                title={t`Create project and Set up the message form`}
                description={t`moin supports highly customizable message form`}
                stepNum={1}
            />
            <Arrow />
            <Step
                icon={<FiSettings tw="text-red-600 text-3xl" />}
                title={t`Add widget at your website`}
                description={t`Easily integrate widget in your website With only one line of code`}
                stepNum={2}
            />
            <Arrow />
            <Step
                icon={<RiShip2Line tw="text-red-600 text-3xl" />}
                title={t`Deploy as either a mobile or web`}
                description={t`The moin widget fully supports web and mobile website`}
                stepNum={3}
            />
        </Wrapper>

    </Container>
);

export default Features;
