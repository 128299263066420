import * as React from 'react';
import tw, { styled } from 'twin.macro';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { CiaoCiaoForm } from '@ctxhou/ciaociao-form-react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/cjs/styles/hljs';

import { BlockHeadline, IntroduceHeadline, IntroduceSubline } from '../Headline';
import Container from '../Container';
import { EN_LOCALE, ZH_HANT_LOCALE } from '../../config';

const PluginWrapper = styled.div`
    height: 540px;
    > div {
        @media (max-width: 375px) {
            width: 90vw !important;
        }
    }
`;

const PluginTitle = tw.div`mb-3 text-center`;

const IntegrateWrapper = tw.div`py-8 px-10 bg-black rounded-tr-3xl rounded-bl-3xl text-white`;

const zhHantPluginConfig = {
    uiConfig: {
        ctaTitle: '線上支援',
        color: 'black',
        successMessage: '24小時內聯繫您',
        errorMessage: 'sdfsdfsdfsdf',
        submitButtonText: '送出',
        icon: 'call',
    },
    config: [
        {
            id: 'senderEmail',
            hint: '我們會透過 Email 與您聯繫',
            type: 'email',
            component: 'input',
            labelText: 'Email',
            validators: [
                'required',
                'email',
            ],
            placeholder: '輸入 Email',
        },
        {
            id: 'id5',
            type: 'text',
            component: 'textarea',
            labelText: '有什麼是我們可以為您服務的？',
            validators: [
                'required',
            ],
            placeholder: '告訴我們有什麼可以為您服務的！',
        },
    ],
};

const enPluginConfig = {
    uiConfig: {
        ctaTitle: t`Feedback`,
        color: 'black',
        successMessage: t`Will contact you in 24 hours`,
        errorMessage: 'sdfsdfsdfsdf',
        submitButtonText: t`Send`,
        icon: 'call',
    },
    config: [
        {
            id: 'senderEmail',
            hint: 'We will contact you via Email',
            type: 'email',
            component: 'input',
            labelText: 'Email',
            validators: [
                {
                    logic: 'required',
                },
            ],
            placeholder: t`Input Email`,
        },
        {
            id: 'id5',
            type: 'text',
            component: 'textarea',
            labelText: t`How can we help you?`,
            validators: [
                {
                    logic: 'required',
                },
            ],
            placeholder: '',
        },
    ],
};

const enPluginConfig2 = {
    uiConfig: {
        ctaTitle: t`Customer Support`,
        color: '#335EEA',
        successMessage: t`Will contact you in 24 hours`,
        errorMessage: 'sdfsdfsdfsdf',
        submitButtonText: t`Send`,
        icon: 'call',
    },
    config: [
        {
            id: 'JDiEE',
            type: 'customized',
            options: [
                {
                    label: t`Billing`,
                    value: 'QDhp4',
                },
                {
                    label: t`Account`,
                    value: 'S_f9h',
                },
                {
                    label: t`Product issue`,
                    value: 'V1GFF',
                },
                {
                    label: t`Refund`,
                    value: 'bTWky',
                },
                {
                    label: t`Others`,
                    value: 'HDU2q',
                },
            ],
            component: 'select',
            labelText: t`Question category`,
            validators: [
                {
                    logic: 'required',
                },
            ],
            placeholder: t`New Field`,
        },
        {
            id: 'id2',
            type: 'name',
            component: 'input',
            labelText: t`Name`,
            validators: [
                {
                    logic: 'required',
                },
            ],
            placeholder: t`Input your name`,
        },
        {
            id: 'id3',
            hint: 'We will contact you via Email',
            type: 'senderEmail',
            component: 'input',
            labelText: t`Contact Email`,
            validators: [
                {
                    logic: 'required',
                },
                {
                    logic: 'email',
                },
            ],
            placeholder: t`Contact Email`,
        },
        {
            id: 'id4',
            type: 'phone',
            component: 'input',
            labelText: t`Contact Phone number`,
            validators: [
                {
                    logic: 'required',
                },
            ],
            placeholder: t`Input contact phone number`,
        },
        {
            id: 'id5',
            type: 'content',
            component: 'textarea',
            labelText: t`Message`,
            validators: [
                {
                    logic: 'required',
                },
            ],
            placeholder: '',
        },
    ],
};

const enPluginConfig3 = {
    uiConfig: {
        ctaTitle: t`Price inquiry`,
        color: '#FF6900',
        successMessage: t`Will contact you in 24 hours`,
        errorMessage: 'sdfsdfsdfsdf',
        submitButtonText: t`Send`,
        icon: 'call',
    },
    config: [
        {
            id: 'id2',
            type: 'name',
            component: 'input',
            labelText: t`Name`,
            validators: [
                {
                    logic: 'required',
                },
            ],
            placeholder: t`Input your name`,
        },
        {
            id: 'senderEmail',
            hint: 'We will contact you via Email',
            type: 'email',
            component: 'input',
            labelText: t`Email`,
            validators: [
                {
                    logic: 'required',
                },
            ],
            placeholder: t`Input Email`,
        },
        {
            id: 'asdasd',
            type: 'input',
            component: 'input',
            labelText: t`Product`,
            validators: [
                {
                    logic: 'required',
                },
            ],
            placeholder: t`Which product to quote?`,
        },
        {
            id: 'id5',
            type: 'text',
            component: 'textarea',
            labelText: t`Note`,
            validators: [
                {
                    logic: 'required',
                },
            ],
            placeholder: '',
        },
    ],
};
const pluginConfig = {
    [EN_LOCALE]: enPluginConfig,
    [ZH_HANT_LOCALE]: zhHantPluginConfig,
};

const codeString = '<script src="https://cdn.moin.bz/widget.js" data-pid="DFevs12" />';

const Integrate = () => (
    <IntegrateWrapper>
        <IntroduceHeadline>
            <Trans>Easily integrate</Trans>
        </IntroduceHeadline>
        <IntroduceSubline>
            <Trans>Only one line of code</Trans>
        </IntroduceSubline>
        <SyntaxHighlighter language="html" style={atomOneDark} customStyle={{ borderRadius: '3px', padding: '1em 1.5em' }}>
            {codeString}
        </SyntaxHighlighter>
    </IntegrateWrapper>
);

const Widget = () => {
    const { i18n } = useLingui();

    return (
        <Container>
            <BlockHeadline>
                <Trans>Plugin Demo</Trans>
            </BlockHeadline>
            <div tw="grid items-center xl:grid-cols-3 gap-10 mb-5">
                <div tw="m-auto">
                    <PluginTitle>
                        <Trans>Example</Trans>
                        {' '}
                        1
                    </PluginTitle>
                    <PluginWrapper>
                        <CiaoCiaoForm
                            pluginConfig={pluginConfig[i18n.locale]}
                            schemaId="test-id"
                            isInline
                            isShownModal
                            isPreview
                        />
                    </PluginWrapper>
                </div>
                <div tw="hidden xl:block">
                    <PluginTitle>
                        <Trans>Example</Trans>
                        {' '}
                        2
                    </PluginTitle>
                    <PluginWrapper>
                        <CiaoCiaoForm
                            pluginConfig={enPluginConfig2}
                            schemaId="test-id"
                            isInline
                            isShownModal
                            isPreview
                        />
                    </PluginWrapper>
                </div>
                <div tw="hidden xl:block">
                    <PluginTitle>
                        <Trans>Example</Trans>
                        {' '}
                        3
                    </PluginTitle>
                    <PluginWrapper>
                        <CiaoCiaoForm
                            pluginConfig={enPluginConfig3}
                            schemaId="test-id"
                            isInline
                            isShownModal
                            isPreview
                        />
                    </PluginWrapper>
                </div>
            </div>
            <Integrate />
        </Container>
    );
};
export default Widget;
