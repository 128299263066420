import * as React from 'react';
import tw, { css } from 'twin.macro';
import { Trans } from '@lingui/macro';

import { appUrl } from '../../config';
import Header from '../Header';
import bikeVisualImage from './bike-visual.svg';
import chientaiImage from './chientai.svg';
import evertoolImage from './evertool.svg';

const Subtitle = tw.p`max-w-screen-lg text-lg sm:text-2xl text-gray-500 sm:leading-10 font-medium mb-10 sm:mb-11`;
const CTASection = tw.div`mt-6`;
const CTALink = tw.a`inline-flex items-center w-auto flex-none bg-blue-700 hover:bg-blue-600 text-white text-lg leading-6 font-semibold py-3 px-6 border border-transparent rounded-xl focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-900 focus:outline-none transition-colors duration-200`;
const Svg = tw.svg`ml-3`;
const customerLogo = css`
    opacity: 0.7;
    filter: grayscale(100%);
    ${tw`h-12 m-auto`}
`;

const HomeHeader = () => (
    <div tw="md:px-0 px-6">
        <Header />
        <div tw="max-w-7xl mx-auto text-center md:py-28 py-14">
            <div css={{ height: '35vh' }}>
                <h1 tw="md:text-6xl text-3xl font-semibold text-gray-900 leading-normal">
                    <Trans>
                        A web message widget just works
                    </Trans>
                </h1>
                <div tw="mt-6 text-xl font-light text-gray-500 antialiased">
                    <Trans>
                        Collect the feedback, idea, order via Email get the realtime notification
                    </Trans>
                </div>
                <CTASection>
                    <CTALink
                        href={appUrl}
                    >
                        <Trans>Get Started for Free</Trans>
                        <Svg width="7" height="12" viewBox="0 0 7 12" fill="none"><path d="M5.95 5.293l-4.24-4.24a1 1 0 10-1.42 1.41l3.54 3.54-3.54 3.54a1 1 0 000 1.41.998.998 0 00.71.29 1 1 0 00.71-.29l4.24-4.24a1 1 0 000-1.42z" fill="currentColor" /></Svg>
                    </CTALink>
                </CTASection>
            </div>
            {/* Testnomial */}
            <div tw="mt-20">
                <p tw="mb-4 text-xl text-gray-500">
                    <Trans>
                        Trusted by
                    </Trans>
                </p>
                <div tw="grid grid-cols-3 gap-10 items-center">
                    <img alt="chientai.com.tw" css={customerLogo} tw="py-2" src={chientaiImage} />
                    <img alt="evertool.app" css={customerLogo} src={evertoolImage} />
                    <img alt="visualization.bike" css={customerLogo} src={bikeVisualImage} />
                </div>
            </div>
        </div>
    </div>
);

export default HomeHeader;
